<template>
  <StdPageContainer type="create">
    <template slot="title">
      <!-- NOTE: 标题栏: 开发人员根据业务调整 -->
      <StdPageTitle :title="!$route.query.id ? '创建' : '编辑'"></StdPageTitle>
    </template>

    <template slot="content">
      <begin-play :webinarId="$route.params.id"></begin-play>
      <RedPacketEdit
        @save="toList"
        :webinarId="$route.params.id"
        :type="$route.query.type"
        :redPacketId="$route.query.id"
      ></RedPacketEdit>
    </template>
  </StdPageContainer>
</template>
<script>
  import { StdPageContainer, StdPageTitle } from '@vhcl/ui-console';
  import { RedPacketEdit } from '@vhcl/red-packet';
  import beginPlay from '@/components/beginBtn';
  export default {
    components: {
      StdPageContainer,
      StdPageTitle,
      RedPacketEdit,
      beginPlay
    },
    methods: {
      toList() {
        this.$router.push({
          path: `/live/CashPacket/${this.$route.params.id}`,
          query: { tab: this.$route.query.type }
        });
      }
    }
  };
</script>
